import React, { useState, useEffect } from "react";
import Banner from "./components/Banner/Index";
import Container from "./components/Container/Index";
import "./components/Products/Index.css";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
// import data from "./Json/ProductCat.json";
import ProListing from "./components/Products/ProListing";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { API_BASE_URL } from "./Utils/useApi";

const ProductCat = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/product_masters.php`); 
        setData(response.data);
        // console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading)
    return (
      <div>
        <div className="preloader flex-center">
          <CircularProgress size={24} />
        </div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!data) return <div>No data available</div>;

  const { homeBanners, power_solutions } = data;

  return (
    <>
      <main>
        {homeBanners && <Banner Data={homeBanners[0]} />}
        {power_solutions && <ProListing Data={power_solutions} />}
        <BusinessEnquiry />
      </main>
    </>
  );
};

export default ProductCat;
