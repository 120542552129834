import React from 'react';

const Index = ({ firstHeading, secondHeading, _class, _secondHeading, subTitle, headingThree, headingTwo, headingOne, _spanClass, Subheading, sub_title }) => {
  const HeadingComponent = headingOne ? 'h1' :
                           headingTwo ? 'h2' :
                           headingThree ? 'h3' :
                           'h2';  

  return (
    <div className={`title ${_class}`}>
      {firstHeading && (
        <span
        className={`miniheading ${_spanClass}`}
          data-aos="zoom-in"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
          dangerouslySetInnerHTML={{ __html: firstHeading }}
        />
      )}
      {secondHeading && (
        <HeadingComponent
          className={`heading primary-color ${_secondHeading}`}
          data-aos="flip-down"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
          dangerouslySetInnerHTML={{ __html: secondHeading }}
        />
      )}
      {Subheading && (
       <h3 data-aos="flip-down"
        data-aos-offset="100"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
        dangerouslySetInnerHTML={{ __html: sub_title }}  />
      )}
      {subTitle && (
        <p
          className="col-12 float-start m-0"
          data-aos="fade-in"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"
          dangerouslySetInnerHTML={{ __html: subTitle }}  
        />
      )}
    </div>
  );
};

export default Index;
