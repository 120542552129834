import React, { useState, useEffect } from "react";
import axios from "axios";
import Heroslider from "./components/Heroslider/Index";
import Overview from "./components/Description/Index";
import CountLoader from "./components/CountLoader/Index";
import ProListing from "./components/Products/ProListing";
import Client from "./components/Client/Index";
import GallerySlider from "./components/Gallery/GallerySlider";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import { API_BASE_URL } from "./Utils/useApi";
import Download from "./images/Vectors/Download";
import WebContainer from './components/WebContainer/Index';
import { CircularProgress } from "@mui/material";

const Home = () => {
  const [homeData, setHomeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHomeData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/home.php`);
        setHomeData(response.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchHomeData();
  }, []);

  if (loading)
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!homeData) return null;

  const {
    homeBanners,
    aboutUs,
    numbers,
    power_solutions,
    clientData,
    gallery,
    corporate_brochure,
  } = homeData;

  return (
    <main>
      {homeBanners[0].imagePath !== "" && <Heroslider Data={homeBanners} />}
      {aboutUs.overview.length > 0 && <Overview Data={aboutUs} />}
      {numbers && <CountLoader Data={numbers} />}
      {power_solutions && <ProListing Data={power_solutions} />}
      {clientData && <Client Data={clientData} bgImage={'client_Bg_Image'}/>}
      {gallery && <GallerySlider Data={gallery} />}
      <BusinessEnquiry />
      {corporate_brochure && 
      <WebContainer>
              <div className='col-12 float-start flex-wrap gap-2 downloadBotton flex-center'>
                      <a className='text-white text-uppercase p-3 px-lg-5' href={corporate_brochure} target='_blank'>
                        <Download />Download Corporate Profile
                      </a>
                    </div>
      </WebContainer>
      }
    </main>
  );
};

export default Home;
