// formStatus.js

export const checkFormFilled = () => {
  return localStorage.getItem("formFilled") === "true";
};

export const setFormFilled = (status, responseData) => {
  localStorage.setItem("formFilled", status);
  if (status === "true") {
    localStorage.setItem("formResponse", JSON.stringify(responseData));
  } else {
    localStorage.removeItem("formResponse");
  }
};

export const getFormResponse = () => {
  const response = localStorage.getItem("formResponse");
  return response ? JSON.parse(response) : null;
};
