import React from 'react'

const Work = () => {
  return (
    <>    
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2.01 57.856 57.856"><path  d="M57.858 16.112c-.007-3.76-2.637-7.035-6.218-7.073H39.973v-5.2A3.841 3.841 0 0 0 36.141.004H21.715a3.843 3.843 0 0 0-3.837 3.835v5.2H6.216C2.632 9.077.005 12.352 0 16.112v6.763a1.665 1.665 0 0 1 0 .258 3.836 3.836 0 0 0 .106.9 6.505 6.505 0 0 0 2.707 3.634v17.641a8.558 8.558 0 0 0 8.536 8.536h35.156a8.563 8.563 0 0 0 8.538-8.536V27.662a6.53 6.53 0 0 0 2.7-3.641 3.729 3.729 0 0 0 .106-.888v-.258ZM20.29 3.838a1.438 1.438 0 0 1 1.425-1.424h14.426a1.438 1.438 0 0 1 1.425 1.424v5.2H20.285Zm32.339 25.267v16.2a6.163 6.163 0 0 1-6.124 6.125H11.349a6.167 6.167 0 0 1-6.127-6.125v-16.2c.082.041.186.078.27.119.466.22.949.434 1.458.642a54.109 54.109 0 0 0 14.793 3.076v2.569a3.018 3.018 0 0 0 3.012 3.012h8.339a3.016 3.016 0 0 0 3.012-3.012v-2.573a70.2 70.2 0 0 0 7.307-.993 42.571 42.571 0 0 0 7.489-2.083c.515-.21 1-.427 1.469-.648l.258-.112Zm-28.476 1.587v-1.108a.617.617 0 0 1 .6-.6h8.339a.617.617 0 0 1 .6.6v5.926a.62.62 0 0 1-.6.6h-8.334a.619.619 0 0 1-.6-.6v-4.818Zm27.485-19.236c1.929 0 3.8 1.965 3.8 4.659v6.723a1.3 1.3 0 0 0-.007.161 1.387 1.387 0 0 0 .007.176 2.162 2.162 0 0 1-.047.326c-.124.827-1.153 1.956-3.012 3.008-.134.076-.274.151-.415.223s-.276.143-.424.215a14.25 14.25 0 0 1-.551.259c-.309.141-.63.28-.977.414a45.067 45.067 0 0 1-9.778 2.42c-1.32.194-2.7.352-4.132.477v-.935a3.016 3.016 0 0 0-3.012-3.014h-8.332a3.017 3.017 0 0 0-3.012 3.014v.935q-1.74-.153-3.375-.375A46.383 46.383 0 0 1 7.838 27.62a20.628 20.628 0 0 1-1-.422c-.174-.079-.338-.158-.5-.238s-.328-.16-.485-.242c-.129-.068-.253-.133-.375-.2-1.865-1.055-2.9-2.186-3.024-3.009a2.913 2.913 0 0 1-.048-.322c0-.024.006-.067.011-.129v-.126l-.011-.126v-6.693c0-2.694 1.875-4.657 3.806-4.659Z" data-name="suitcase work"></path></svg>
    </>
  )
}

export default Work