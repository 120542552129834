import React from 'react'

export const Instagram = (props) => {
  return (
    <>
     <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 800 800"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#808184;stroke-width:0}"}</style>
    </defs>
    <path
      d="M400-.88C178.97-.88-.88 178.97-.88 400S178.97 800.87 400 800.87 800.87 621.02 800.87 400 621.02-.88 400-.88Zm0 775c-206.3 0-374.13-167.82-374.13-374.13S193.7 25.87 400 25.87 774.13 193.7 774.13 400 606.31 774.13 400 774.13Z"
      className="cls-1"
    />
    <path
      d="M464.98 334.84a90.994 90.994 0 0 0-29.81-19.89 91.063 91.063 0 0 0-34.93-6.93h-.23c-18.22 0-35.86 5.35-51 15.47-15.15 10.12-26.84 24.37-33.81 41.2-6.97 16.83-8.78 35.17-5.22 53.04 3.56 17.87 12.24 34.12 25.12 47a91.495 91.495 0 0 0 47 25.12c17.87 3.56 36.21 1.75 53.04-5.22s31.08-18.66 41.2-33.81c10.12-15.15 15.47-32.78 15.47-51 .03-12.14-2.3-23.97-6.93-35.17a91.15 91.15 0 0 0-19.89-29.81Zm-18.62 111.34c-12.38 12.38-28.85 19.2-46.36 19.2-13.01 0-25.61-3.82-36.43-11.05a65.353 65.353 0 0 1-24.15-29.42 65.328 65.328 0 0 1-3.73-37.88 65.365 65.365 0 0 1 17.94-33.57c9.2-9.2 20.81-15.4 33.57-17.94a65.328 65.328 0 0 1 37.88 3.73 65.353 65.353 0 0 1 29.42 24.15 65.331 65.331 0 0 1 11.05 36.42c0 17.51-6.82 33.98-19.2 46.36ZM505.88 318.81a19.68 19.68 0 0 0 8.86-7.27c2.18-3.26 3.33-7.05 3.33-10.97 0-5.27-2.05-10.23-5.78-13.96a19.614 19.614 0 0 0-13.96-5.78c-3.92 0-7.71 1.15-10.97 3.33a19.68 19.68 0 0 0-7.27 8.86c-1.5 3.62-1.89 7.56-1.12 11.41.76 3.84 2.63 7.34 5.41 10.11 2.77 2.77 6.27 4.64 10.11 5.4 3.84.76 7.79.38 11.41-1.12Z"
      className="cls-1"
    />
    <path
      d="M581.09 323.82h-.03l.01-.7c.5-27.91-9.78-54.42-28.98-74.71-19.9-19.17-45.93-29.66-73.49-29.66-.61 0-1.22 0-1.84.02h-.22c-30.05-1.39-123.05-1.39-153.09 0h-.24c-28.09-.62-54.86 9.68-75.32 28.96-19.33 20.44-29.62 47.18-28.96 75.31v.24c-1.39 30.05-1.39 123.04 0 153.09v.24c-.66 28.13 9.63 54.87 28.96 75.31 20.46 19.29 47.22 29.55 75.32 28.96h.28c30.74 1.86 122.25 1.86 152.99 0h.3c28.13.65 54.87-9.62 75.31-28.96 19.34-20.44 29.62-47.18 28.96-75.31v-.24c1.38-29.87 1.39-121.95.02-152.55ZM548.55 510.2v.24l-.24.62c-3.35 8.49-8.33 16.07-14.78 22.52s-14.03 11.43-22.52 14.78l-.29.11-.3.06c-36.3 7.34-73.45 9.87-110.41 7.51-8.91.57-17.83.85-26.75.85-27.99 0-55.95-2.8-83.42-8.37l-.3-.06-.29-.11a65.871 65.871 0 0 1-22.52-14.78 65.821 65.821 0 0 1-14.78-22.52c-8.69-21.93-8.27-68.03-8-98.52.04-4.62.08-8.83.08-12.47s-.04-7.85-.08-12.48c-.28-30.58-.7-76.79 8-98.54 3.24-8.53 8.18-16.19 14.65-22.71 6.48-6.52 14.1-11.5 22.67-14.82l.28-.11.29-.06c36.22-7.34 73.29-9.87 110.18-7.52 36.96-2.35 74.11.18 110.41 7.52l.3.06.29.11a65.726 65.726 0 0 1 22.52 14.78 66.017 66.017 0 0 1 14.78 22.52c8.6 21.7 8.11 67.33 7.79 97.53-.05 4.93-.1 9.58-.1 13.47s.05 8.55.1 13.48c.32 29.98.8 75.13-7.54 96.89Z"
      className="cls-1"
    />
  </svg>
    </>
  )
}
