import React from "react";

const Index = (props) => {
  return (
    <section className={`col-12 float-start ${props._parentClass}`}  {...(props.sectionID && { id: props.sectionID })}>
      <div className="container float-end">
        <div className="row">
          <div className="web-container">{props.children}</div>
        </div>
      </div>
    </section>
  );
};

export default Index;
