import React from "react";
import "./index.css";
import Container from "../Container/Index";
import Swiperslider from "../Swiperslider/Index";
import ImageTag from "../ImageTag/Index";
import Title from "../Title/Index"

const Featureslider = ({ Data }) => {
  return (
    <Container
      containerClass={"float-end p-0"}
      sectionID={'features'}
    >
      
        <div className="col-lg-6 col-sm-12 col-12 m-auto">
        <Title firstHeading={Data.heading} secondHeading={Data.title} />
        </div>
      <div
        className="featureSlider bottomPosition"
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <Swiperslider slidesPerView={3}  spaceBetween={75} navigation={true} timeDelay={'600000'} tabspaceBetween={15}>
          {Data.featureData.map((logo, index) => (
            <div
              className="col-12 position-relative featureSlide"
              key={index}
            >
              <ImageTag ImagePath={logo.imagePath} ImageAlt={logo.imageAlt} />
              <span className="featureName text-uppercase text-center">{logo.imageAlt}</span>
              <div className="infoManufac flex-center">
              <div className="col-12 float-start d-flex flex-wrap gap-2">
              <span className="featureName text-uppercase fw-bold position-relative px-lg-4 px-3"><span className="greenBorder"></span>{logo.imageAlt}</span>
              <p className="m-0 px-lg-4 px-3">{logo.description}</p>
              </div>
              </div>
            </div>
          ))}
        </Swiperslider>
      </div>
    </Container>
  );
};

export default Featureslider;