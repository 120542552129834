import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Sticky, StickyContainer } from "./components/Sticky/Index";
import Banner from "./components/Banner/Index";
import Container from "./components/Container/Index";
import FilterAccordion from "./components/Products/Filter";
import ProductCard from "./components/Products/ProductCard";
import "./components/Products/Index.css";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import axios from "axios";
import { API_BASE_URL } from "./Utils/useApi";
import { CircularProgress } from "@mui/material";
import queryString from "query-string";
import Title from "./components/Title/Index";
import BreadCrumbs from "./components/BreadCrumb/Index";
import Filter from "./images/Vectors/Filter";

const Products = () => {
  const { slug } = useParams();
  const tabContentRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isStickyActive, setStickyActive] = useState(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    type: [],
    kvaRange: [],
  });

  // Fetch products based on category (slug)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/product_listing.php?cat=${slug}`
        );
        setData(response.data);

        // Check if filters exist in the API response, otherwise use default filters
        if (response.data?.filters && Array.isArray(response.data.filters)) {
          setFilterData(response.data.filters);
        } else {
          setFilterData([]);
        }

        setFilteredProducts(response.data?.productList || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  // Apply filters
  const applyFilters = (filterKey, value) => {
    setActiveFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      const index = updatedFilters[filterKey].indexOf(value);
      if (index > -1) {
        updatedFilters[filterKey].splice(index, 1); // Remove filter if exists
      } else {
        updatedFilters[filterKey].push(value); // Add filter
      }
      return updatedFilters;
    });
  };

  // Update URL with filters
  const updateURLWithFilters = (filters) => {
    const newParams = {};

    if (filters.type.length) {
      newParams.type = filters.type.join(",");
    }

    if (filters.kvaRange.length) {
      newParams.kvaRange = filters.kvaRange.join(",");
    }

    const query = queryString.stringify(newParams);
    navigate({ pathname: location.pathname, search: `?${query}` });
  };
  const handleFilterClick = () => {
    setStickyActive((prev) => !prev); // Toggle the active state
  };
  // Sync URL with filters
  useEffect(() => {
    updateURLWithFilters(activeFilters);
  }, [activeFilters]);

  const parseKVARange = (range) => {
    // Use the correct delimiter and strip the "kVA" text
    const [minStr, maxStr] = range.replace(" kVA", "").split(" - ");

    // Parse the min and max values as floats
    const min = parseFloat(minStr);
    const max = parseFloat(maxStr);
    // console.log("this is hte min range", min)
    // console.log("this is hte max range", max)

    return {
      min: isNaN(min) ? 0 : min, // Default to 0 if min is not available
      max: !isNaN(max) ? max : min, // If max is missing, use min as max (acts as a specific value)
    };
  };

  // Helper function to check if a value is within a range



  
  // Helper function to check if there is an overlap between two ranges
  const isWithinRange = (productRange, filterRange) => {
    const product = parseKVARange(productRange);
    const filter = parseKVARange(filterRange);

    // Check if there is any overlap between product and filter range
    const isOverlap =
      (product.min >= filter.min && product.min <= filter.max) || // Product min is within filter range
      (product.max >= filter.min && product.max <= filter.max) || // Product max is within filter range
      (filter.min >= product.min && filter.min <= product.max); // Filter range is within product range

    return isOverlap;
  };

  // Filter products based on active filters
  useEffect(() => {
    if (data?.productList) {
      const filtered = data.productList.filter((product) => {
        const typeMatch =
          activeFilters.type.length === 0 ||
          activeFilters.type.includes(product.type);

        let kvaMatch = true;
        if (activeFilters.kvaRange.length > 0) {
          kvaMatch = activeFilters.kvaRange.some((range) =>
            isWithinRange(product.kva_range, range)
          );
        }

        return typeMatch && kvaMatch;
      });

      setFilteredProducts(filtered);
    }
  }, [activeFilters, data]);

  if (loading)
    return (
      <div>
        <div className="preloader flex-center">
          <CircularProgress size={24} />
        </div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!data)
    return <div className="preloader flex-center">No data available</div>;

  const { overview } = data || {};

  return (
    <main>
      <div className="col-12 float-start">
        <BreadCrumbs />
      </div>
      <Container containerClass={"p-0 float-end"} _parentClass={"productPage"}>
        <div className="col-lg-11 col-11 m-auto">
          {overview && (
            <div className="col-lg-6 col-12 m-auto">
              <Title
                firstHeading={overview.title}
                secondHeading={overview.subTitle}
                subTitle={overview.descp}
              />
            </div>
          )}
        </div>
        <div ref={tabContentRef} className="col-12 float-start d-flex product">
         
        <div className="button btn button-container-1 filterbttn  text-white" onClick={handleFilterClick}>
      {isStickyActive ? (<><span >Apply Filter</span></>) : (<><span > Filter</span> <Filter /></>)}
      </div>
          <StickyContainer className={`sticky-container desktop-show ${isStickyActive ? 'active' : ''}`}>
              <div className="mobile-show position-absolute top-0 end-0 text-white p-3" onClick={handleFilterClick}>
                <span className="crossFilter">X</span>
              </div>
            <Sticky topOffset={-150}>
              {({ style }) => (
                <div className="header" style={style}>
                  <FilterAccordion
                    Data={filterData}
                    applyFilters={applyFilters}
                    activeFilters={activeFilters}
                  />
                </div>
              )}
            </Sticky>
          </StickyContainer>
          <div className="nonStickycontainer">
            <div className="row">
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product, index) => (
                  <div className="col-lg-4 col-sm-6 col-12 p-0" key={index}>
                    <ProductCard Data={product} />
                  </div>
                ))
              ) : (
                <div className="col-12 text-center flex-center">
                  <p>No products available</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      <BusinessEnquiry />
    </main>
  );
};

export default Products;
