import React from "react";
import { Link, useLocation } from "react-router-dom";

const   MediaNavabar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-12 media_main_contianer">
      <ul className="medianavbar">
        <li className={pathname === "/media/press-release" ? "active" : ""}>
          <Link to="/media/press-release">Press Release</Link>
        </li>
        <li className={pathname === "/media/events" ? "active" : ""}>
          <Link to="/media/events">Events</Link>
        </li>
        <li className={pathname === "/media/videos" ? "active" : ""}>
          <Link to="/media/videos">Video</Link>
        </li>
      </ul>
    </div>
  );
};

export default MediaNavabar;
