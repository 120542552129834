import React from 'react'

const DoubleQuote = (props) => {
  return (
    <>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 8 8"
    {...props}
  >
    <path d="M3 1C1.35 1 0 2.35 0 4v3h3V4H1c0-1.11.89-2 2-2V1zm5 0C6.35 1 5 2.35 5 4v3h3V4H6c0-1.11.89-2 2-2V1z" />
  </svg>
    </>
  )
}

export default DoubleQuote