import React from "react";
import WebContainer from "../WebContainer/Index";
import Category from "./Categorycard";
import "./Index.css";
import Quote from "../../images/Vectors/Quote";
import FillShape from "../../images/Vectors/FillShape";
import Title from '../Title/Index'

const ProListing = ({ Data }) => {
  return (
    <WebContainer _parentClass={"productStn position-relative"} >
      <div className="bottomShape">
        <FillShape />
      </div>
      <div className="row">
        <div className="col-12 float-start">
          <div className="col-lg-10 col-sm-12 col-12 m-auto">
            <div className="row">
                <Title firstHeading={Data.heading} secondHeading={Data.title} subTitle={Data.descp}/>
              {Data.category.map((product, index) => (
                <div
                  className="col-lg-6 col-sm-6 col-12 p-0"
                  key={index}
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="500"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                >
                  <Category Data={product} />
                </div>
              ))}
              {Data.quote && 
              <div
                className="col-lg-6 col-sm-6 col-12  p-0"
                data-aos="fade-in"
                data-aos-offset="200"
                data-aos-duration="500"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
              >
                <div className="cardStnComponent card bg-secondary position-relative text-center">
                  <span className="svgIcon">
                    <Quote />
                  </span>
                  <h4>{Data.quote}</h4>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="topShape">
        <FillShape />
      </div>
    </WebContainer>
  );
};

export default ProListing;
