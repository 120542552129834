import React from 'react'
import Container from '../Container/Index'
import Title from '../Title/Index'

const VisionMission = ({Data}) => {
  return (
    <>
        <Container containerClass={'float-end'} _parentClass={'ourCorporate bigTitle'} sectionID={'ethos'}>
        <div className="col-12 float-start corporate_Bg_Image p-100 blueLayer">
            <div className='row'>
            <Title firstHeading={Data.title} _spanClass={'text-white '}/>
                <div className='col-lg-8 col-sm-11 col-11 m-auto'>
                    <div className='row justify-content-between position-relative text-white'>
                    <div className='col-lg-4 col-sm-6 col-12' data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine">
                    <Title secondHeading={Data.mission.title} _secondHeading={'text-white text-start'}  _class={'text-white text-start mb-1'}/>
                    <p dangerouslySetInnerHTML={{__html : Data.mission.content}} data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"/>
                        </div>
                        <div className='col-lg-6 col-sm-6 col-12'>
                        <Title secondHeading={Data.vision.title} _secondHeading={'text-white text-start'}  _class={'text-white text-start mb-2'}/>
                            <ul className='listitemsVision d-flex gap-3 flex-wrap px-4' data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine">
                                <li dangerouslySetInnerHTML={{__html : Data.vision.content1}} />
                                <li dangerouslySetInnerHTML={{__html : Data.vision.content2}} />
                                <li dangerouslySetInnerHTML={{__html : Data.vision.content3}} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Container>
        <Container containerClass='float-end' _parentClass='Vision bigTitle'>
            <Title firstHeading={Data.values.title} _class={'mb-4'}/>
            <div className='col-lg-6 col-sm-12 col-12 m-auto flex-center text-center'>                
            <ul data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="500"
          data-aos-once="true"
          data-aos-easing="ease-in-sine"  >
            {Data.values.listitems.map((items, index) => (
          <li key={index}>
            <span>{items.title}</span>
            <p>{items.description}</p>
          </li>
        ))}
      </ul>                
            </div>
        </Container>
    </>
  )
}

export default VisionMission