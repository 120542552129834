import React from 'react';
import ImageTag from '../ImageTag/Index';

const Categorycard = ({ Data }) => {
  return (
    <div className='cardStnComponent card'>
      <div className='col-12 float-start icons text-end'>
        <ImageTag ImagePath={Data.imagePath} alt={Data.title} />
      </div>
      <div className='col-12 float-start productContent'>
        <h3 className='productTitle text-uppercase h-auto'>{Data.title}</h3>
        <p>{Data.descp}</p>
      </div>
      <div className='col-12 float-start productKnowmore flex-center justify-content-end'>
        {Data.slug && <a href={`power-solutions/${Data.slug}`}><span className='flex-center'>+</span></a>}
      </div>
    </div>
  );
};

export default Categorycard;
