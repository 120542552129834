import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from '../Logo/Index'
import Title from '../../Title/Index'

const Mobilenav = ({ Data }) => {
  const [menus, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!menus);
  };

  const handleMenuClick = () => {
    setActive(false);
  };

  return (
    <>
      <div
        className="menu_second_child flex-center flex-wrap"
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="800"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <button
          onClick={handleToggle}
          className={menus ? "hamburger active" : "hamburger"}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <span className="col-12 float-start text-center text-uppercase text-white menuText desktop-show">Menu</span>
      </div>

      <div className={menus ? "menus active" : "menus"}>
        <div className="col-12 float-start flex-center dropdown-content slideMenu">
        <div
        className="closedBtn flex-center flex-wrap">
        <button
          onClick={handleToggle}
          className={menus ? "hamburger active" : "hamburger"}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
          <div className="col-lg-9 col-sm-12 col-12 float-end menuAlignmob">
          <div className="row flex-center">
            <div className="col-lg-4 col-sm-6 col-12">
            <ul>
            {Data.map((item, index) => (
              <li key={index} className="text-uppercase text-white">
                <NavLink to={item.link || "#"} onClick={handleMenuClick}>
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 desktop-show">
                <div className="logowaterMark">
                  <div className="logo">
                    <Logo />
                  </div>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 desktop-show">
              <div className="col-lg-11 col-12 float-end">
                <Title firstHeading={'Contact Us'} _spanClass={'text-white greenBorder position-relative p-0 px-3'} _class={'mb-4 text-start'}/>
                <div className="contactDetail col-12 float-start">
                  <ul>
                    <li><span>Phone number</span><p><a href="tel:911724374444">+91-172-4374444</a>, <a href="tel:9193160 45838">+91-93160 45838</a></p></li>
                    <li><span>Email address</span><p><a href="mailto:iec.chandigarh@iecgensets.com">iec.chandigarh@iecgensets.com</a></p></li>
                    <li><span>Address </span><p>Industrial Equipements Company Ltd.<br/> S.C.O. 36, Sector 26, Madhya Marg, Chandigarh - 160019 </p><p className="text-uppercase pt-2 fw-600"><a href="https://maps.app.goo.gl/WmfiMmrtpVQKASLT7" target='_blank' rel="noreferrer">View Location Map</a></p></li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mobilenav;
