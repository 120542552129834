import React, { useEffect, useState } from 'react';
import './components/Aboutus/index.css';
import WebContainer from './components/WebContainer/Index';
import Title from './components/Title/Index';
import ImageTag from './components/ImageTag/Index';
import { useParams } from 'react-router-dom';
import Outline from './components/Button/Outline/Index';
import axios from 'axios';
import { API_BASE_URL } from './Utils/useApi';
import { CircularProgress } from '@mui/material';

const Leadership = () => {
  const { slug } = useParams(); 
  const [leaderData, setLeaderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/leadership.php?url=${slug}`);
        const leader = response.data.Leadership.find((member) => member.slug === slug);
        setLeaderData(leader);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [slug]);

  if (loading) {
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  }

  if (error) {
    return <div>Error: An error occurred while fetching data</div>;
  }

  if (!leaderData) {
    return <p>Data not found.</p>;
  }

  return (
    <main>
      <WebContainer _parentClass="p-100">
        <Title firstHeading={leaderData.Designation} secondHeading={leaderData.Name} _class="text-start mb-4" />
        <div className="col-12 float-start">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <ImageTag ImagePath={leaderData.imagePath} ImageAlt={leaderData.Name} />
            </div>
            <div className="col-lg-8 col-sm-6 col-12">
              <p dangerouslySetInnerHTML={{ __html: leaderData.Description }} />
            </div>
            <div className="col-12 float-start mt-4">
              <Outline buttonText={'More Leaders'} pageLink={'/about-iec-gensets#leadership'} />
            </div>
          </div>
        </div>
      </WebContainer>
    </main>
  );
};

export default Leadership;
