import React from 'react'
import Banner from "./components/Banner/Index";
import Service from './components/Products/Service'
import Container from './components/Container/Index'
import data from "./Json/Manufacturing.json";

const Customer = () => {
  const { bannerData} =
  data;
  return (
    <main>
        {bannerData && <Banner Data={bannerData} />}
    <Container containerClass={'float-end'}>
       <Service />
    </Container>
    </main>
  )
}

export default Customer