import React, { useEffect, useState } from 'react'
import Banner from "./components/Banner/Index";
import CountLoader from './components/CountLoader/Index';
import Overview from './components/Aboutus/Overview';
import Quote from './components/Aboutus/Quote';
import './components/Aboutus/index.css'
import Leadercard from './components/Aboutus/Leadercard';
import VisionMission from './components/Aboutus/VisionMission';
import Moto from './components/Aboutus/Moto';
import GallerySlider from './components/Gallery/GallerySlider';
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import axios from 'axios';
import { API_BASE_URL } from "./Utils/useApi";
import { CircularProgress } from "@mui/material";

const Aboutus = () => {
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
      const fetchData = async () => {
        try{
          const response = await axios.get(`${API_BASE_URL}/about.php`);
          setPageData(response.data); 
        }
        catch(err){
          setError(err.message);
        }
        finally{
          setLoading(false);
        }
      };
      fetchData();
  },[]);
  if (loading)return (
    <div className="preloader flex-center">
      <CircularProgress size={24} />
    </div>
  );
  if(error)return(
    <div>Error: An error occurred while fetching data</div>
  )
  if(!pageData) return <p>Data not found.</p>;
  const { homeBanners, overview, Highlights, numbers, mdMessage, Leadership, corporateEthos, moto, gallery } =
  pageData;
  return (
    <main>
      {homeBanners && <Banner Data={homeBanners[0]} />}
      {overview && <Overview Data={overview} Data2={Highlights}/>}
      {numbers && <CountLoader Data={numbers} aboutUs={'AboutUs'}/>}
      {mdMessage && <Quote Data={mdMessage}/> }
      {Leadership && Leadership.length > 0 && <Leadercard Data={Leadership} />}
      {corporateEthos && <VisionMission Data={corporateEthos}/>}
      {moto && <Moto Data={moto}/>}
      {gallery && <GallerySlider Data={gallery} />}
      <BusinessEnquiry />
    </main>
  )
}

export default Aboutus