import React from "react";
import Data from "./Json/Contact.json";
import BusinessEnquiry from "./components/Forms/BusinessEnquiry";
import BreadCrumbs from "./components/BreadCrumb/Index";

const { bannerData } = Data;
const Contact = () => {
  return (
    <>
      <BreadCrumbs />
      <div className="col-12 float-start p-100">
      <BusinessEnquiry />
      </div>
    </>
  );
};

export default Contact;
