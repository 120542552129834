import React, { useEffect, useState } from "react";
import Call from "../../images/Vectors/Call";
import Email from "../../images/Vectors/Mail";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { API_BASE_URL } from "../../Utils/useApi";
import Title from "../Title/Index";

const ServiceCard = ({
  title,
  description,
  phoneNumbers,
  emailAddresses,
  isEven,
}) => (
  <div
    className={`col-lg-6 col-sm-12 col-12    text-white ${
      isEven ? "border-radius-left pl-0" : "border-radius-right pr-0 " 
    }`}
    data-aos="fade-up"
        data-aos-offset="100"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
  >
    <div
      className={`col-lg-12 col-sm-12 col-12  colorCard ${
        isEven
          ? "float-start border-radius-left bg-primary "
          : "float-end border-radius-right bg-secondary "
      }`}
    >
      <div className="col-lg-10 col-sm-12 col-12">
        <h3 className="fw-600 fs-4">{title}</h3>
        <p>{description}</p>
      </div>
      <div className="col-12 float-start">
        <ul className="d-flex col-12 float-start contectDetails align-item-center pt-4 mt-4 ">
          <li className="col-lg-5 col-12 d-flex align-items-center">
            <span>
              <Call />
            </span>
            <p className="d-grid m-0">
              {phoneNumbers.map((number, index) => (
                <a key={index} href={`tel:${number}`}>
                  +91-{number}
                </a>
              ))}
            </p>
          </li>
          <li className="col-lg-7 col-12 d-flex align-items-center">
            <span>
              <Email />
            </span>
            <p className="m-0">
              {emailAddresses.map((email, index) => (
                <a key={index} href={`mailto:${email}`}>
                  {email}
                </a>
              ))}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

const Service = () => {
  const [pageData, setPageData] = useState(null);
  const [loading, setloading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/customer_support.php`
        );
        setPageData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setloading(false);
      }
    };
    fetchData();
  }, []);
  if (loading)
    return (
      <div className="preloader flex-center">
        <CircularProgress size={24} />
      </div>
    );
  if (error) return <div>Error: An error occurred while fetching data</div>;
  // const pageData = {
  //   title: "After Sales Service",
  //   heading: "Customer Delight in Every Endeavour",
  //   description:
  //     "IEC Electric Power provides top-class customer support and seamless services for your after-sales needs.",
  //   services: [
  //     {
  //       title: "Pre-Installation & Installation Servicing",
  //       description:
  //         "IEC Electric Power provides expert customer assistance for all your needs pre-installation and up to installation.",
  //       phoneNumbers: ["+91-172-4374444", "+91-93160 45838"],
  //       emailAddresses: ["iec.chandigarh@iecgensets.com"],
  //     },
  //     {
  //       title: "Pre-Installation & Installation Servicing",
  //       description:
  //         "IEC Electric Power provides expert customer assistance for all your needs pre-installation and up to installation.",
  //       phoneNumbers: ["8806334433"],
  //       emailAddresses: ["koel.helpdesk@kirloskar.com"],
  //       downloadImage: downloadImage,
  //       downloadAlt: "Download KIRLOSKAR GAS GENSET DATA SHEET",
  //     },
  //   ],
  // };

  return (
    <div className="salesService col-12 float-start flex-center flex-wrap">
      <div className="col-lg-8 col-12 m-auto float-start">
        <Title
          firstHeading={pageData.title}
          secondHeading={pageData.heading}
          subTitle={pageData.description}
          headingOne={true}
        />
      </div>
      <div className="col-12 float-start">
        <div className="row flex-center flex-row ">
          <ServiceCard
            title={pageData.services[0].title}
            description={pageData.services[0].description}
            phoneNumbers={pageData.services[0].phoneNumbers}
            emailAddresses={pageData.services[0].emailAddresses}
            isEven={true}
          />
          <ServiceCard
            title={pageData.services[1].title}
            description={pageData.services[1].description}
            phoneNumbers={pageData.services[1].phoneNumbers}
            emailAddresses={pageData.services[1].emailAddresses}
          />
        </div>
      </div>
    </div>
  );
};

export default Service;
