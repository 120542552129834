import React from 'react'
import Container from '../Container/Index'
import Title from '../Title/Index'
import FillShape from '../../images/Vectors/FillShape'

const Overview = ({Data, Data2}) => {
  return (
    <Container _parentClass="Overview" containerClass={'float-end'}>
          <div className="col-lg-6 col-sm-12 col-12 m-auto position-relative">
            <div className='overviewSign'><FillShape /></div>
            <Title firstHeading={Data.title} secondHeading={Data.heading} subTitle={Data.descp} headingOne={true} />
          </div>
          <div className="col-lg-10 col-sm-12 col-12 m-auto position-relative">
            <div className='row'>
              {Data2.map((items, index) => (
                <div className='col-lg-4 col-12' key={index}   data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="500"
                data-aos-once="true"
                data-aos-easing="ease-in-sine">
                  <div className='cardStnComponent card gradient w-100 text-white radius-25'>
                    <h2 className='productTitle text-uppercase h-auto'>{items.title}</h2>
                    <p>{items.descp}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
  )
}

export default Overview