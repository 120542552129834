import React from "react";
import Counter from "../CountLoader/Loader";
import "../CountLoader/Counter.css";
import Container from "../Container/Index";
import Title from '../Title/Index'

const Index = ({Data, aboutUs}) => {
  return (
    <Container containerClass={'float-end'}>
                <div className={`col-12 float-start number_Bg_Image p-100 blueLayer`}>
                  <div className="Number_Section position-relative col-lg-12 col-sm-12 col-11 m-auto">
              <div className="col-12 float-start">
                <Title secondHeading={Data.title} _secondHeading={'text-white'}/>
              </div>
      <div className={`numberrow col-12 float-start flex-center flex-wrap ${aboutUs}`} data-aos="fade-up" data-aos-offset="200" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-in-sine">
        {Data &&
          Data.numberData.map((counter, index) => (
            <div key={index} className="numbercolumn text-white">
              {counter.startValue !== undefined &&
              counter.endValue !== undefined ? (
                <div className="countercount d-flex">
                  <Counter
                    startValue={counter.startValue}
                    endValue={counter.endValue}
                    speed={counter.speed}
                    className="m-0 p-0"
                  />
                  {counter.plus && <span className="contentIcon">{counter.plus}</span>}
                  {counter.Heading && <span className="contentText">{counter.Heading}</span>}
                  
                </div>
              ) : (
                <div className="countercount d-flex">
                  <span>{counter.Plus}</span>
                  <span className="m-t0">{counter.Heading}</span>
                </div>
              )}
              <div className="countercont m-0 ">
                <p className="m-0 text-white">{counter.label}</p>
              </div>
            </div>
          ))}
      </div></div>
                </div>
    </Container>
  );
};

export default Index;
