import React from 'react'
import WebContainer from '../WebContainer/Index'
import Title from '../Title/Index'
import DoubleQuote from '../../images/Vectors/DoubleQuote'

const Quote = ({Data}) => {
  return (
   <WebContainer _parentClass={'mdMessage'} sectionID={'message'}>
          <div className='col-12 float-start position-relative QuoteTitle text-center'>
          <Title firstHeading={Data.title} _class={'mb-3'}/>
          <p>{Data.descp}</p>
          <div className='doubleQuote'><DoubleQuote /></div>
          </div>
   </WebContainer>
  )
}

export default Quote